import React from "react";
import { Container, Divider, Grid, Header, Image } from "semantic-ui-react";

import SiteFooter from "../components/SiteFooter";
import SiteHeader from "../components/SiteHeader";
import ImageCarousel from "../components/ImageCarousel";

// 2024
const BluePrint_MIT_2024 = "./images/Community/2024_BluePrint_MIT.jpeg";
const ADPList_2024 = "./images/Community/2024_ADPList.jpeg";
const Babson_1_2024="./images/Community/2024_Babson_1.jpeg";
const Babson_2_2024="./images/Community/2024_Babson_2.jpeg";
const Babson_3_2024="./images/Community/2024_Babson_3.jpeg";
const HackDisability_2024="./images/Community/2024_HackDisability.jpeg";
const ProductCMU_2024="./images/Community/2024_ProductCMU.jpeg";
const ProductUnfiltered_2024="./images/Community/2024_Product_Unfiltered.png";
const MIIPS_article_2024="./images/Community/2024_MIIPS_Article.png";

// 2023
const Cornell_1_2023="./images/Community/2023_Cornell_1.jpeg";
const Cornell_2_2023="./images/Community/2023_Cornell_2.jpeg";
const Interactive_Design_2023="./images/Community/2023_Interactive Design.jpg";
const HackHarvard_1_2023="./images/Community/2023_Harvard_1.jpeg";
const HackHarvard_2_2023="./images/Community/2023_Harvard_2.JPG";
const HackMIT_2023="./images/Community/2023_HackMIT.jpeg";

//2022
const CMUonline_2022="./images/Community/2022_CMUonline.png";
const witBoston_1_2022="./images/Community/2022_witBoston_1.jpeg";
const witBoston_2_2022="./images/Community/2022_witBoston_2.jpeg";
const cc_1_2022="./images/Community/2022_cc_1.jpeg";
const cc_2_2022="./images/Community/2022_cc_2.jpeg";
const cc_3_2022="./images/Community/2022_cc_3.jpeg";

//2021 - earlier
const ARS_Electronica_2020="./images/Community/2020_Ars_Electronica.png";
const PlanT_2021="./images/Community/2021_PlanT.png";
const designBuddies_1="./images/Community/2021_DesignBuddies_GHC_1.jpeg";
const designBuddies_2="./images/Community/2021_DesignBuddies_GHC_2.png";
const NID_2014="./images/Community/2014_NID.JPG";

export default function Community() {
    return (
      <>
      <SiteHeader />
      <Divider hidden />

        <Container>
          <Grid stackable>
            <Grid.Row>
              <Grid.Column width ={9}>
                <Header as="h1">Community</Header>
                <br />
                <p style={{ fontSize: "16px" }}>
                I like to give back to the community through mentorship, and 
                by sharing my expertise and career journey. Although I may 
                occasionally question the scale of my impact, I find fulfillment
                in every interaction, which give me the opportunity to inspire others, 
                spark ideas, and guide career decisions, fueling my sense of purpose.</p>
              </Grid.Column>
            </Grid.Row>

            <Divider hidden />

            <Grid.Row><Grid.Column><Header as="h2">2024</Header></Grid.Column></Grid.Row>
            <Grid.Row>
                <Grid.Column width ={4} textAlign="center">
                    <Image src={ProductCMU_2024} rounded bordered/>
                    <p style={{marginTop: "3%"}}>
                    <a href="https://open.spotify.com/episode/1aG34zROlFN0LPEADRYnhF?si=hP1KEGylTM-cL0x2ZM6k0w"rel="noopener noreferrer" target="_blank" style={{color: "inherit"}}><u>
                    Podcast</u></a>{" "}on Product Valley</p>
                </Grid.Column>

                <Grid.Column width ={4} textAlign="center">
                    <Image src={BluePrint_MIT_2024} rounded />
                    <p style={{marginTop: "3%"}}>Judge @ MIT BluePrint</p>
                </Grid.Column>

                <Grid.Column width ={4} textAlign="center">
                    <Image src={ProductUnfiltered_2024} rounded bordered />
                    <p style={{marginTop: "3%"}}>
                    <a href="https://youtu.be/mTQvydSiaf4?si=baVLddySIthA4RSd"rel="noopener noreferrer" target="_blank" style={{color: "inherit"}}><u>
                    Podcast</u></a>{" "}on Product Unfiltered</p>
                </Grid.Column>

                <Grid.Column width ={4} textAlign="center">
                    <Image src={HackDisability_2024} rounded />
                    <p style={{marginTop: "3%"}}>Mentor @ HackDisability, Perkins School for the Blind x MIT</p>
                </Grid.Column>
            </Grid.Row>
            
            {/* 2024 list of contributions  */}
            <Grid.Row>
            <Grid.Column width ={4} textAlign="center">
                    <ImageCarousel
                        showDots={false}
                        sliderContent={[
                        <Image key={1} src={Babson_1_2024} rounded bordered />,
                        <Image key={2} src={Babson_2_2024} rounded bordered />,
                        <Image key={3} src={Babson_3_2024} rounded bordered />,]}
                    />
                    <p style={{marginTop: "3%"}}>Design Thinking &nbsp;
                    <a href="https://www.linkedin.com/posts/productbuildingjamming_designsprint-innovation-productdesign-activity-7180539528221237248-CJcb?" rel="noopener noreferrer" target="_blank" style={{color: "inherit"}}>
                        <u>Workshop</u></a> @ Babson</p>
                </Grid.Column>

                <Grid.Column width ={4} textAlign="center">
                    <Image src={MIIPS_article_2024} rounded />
                    <p style={{marginTop: "3%"}}>Article on &nbsp;
                    <a href="https://medium.com/@harshikerfuffle/applying-to-the-miips-program-at-cmu-9a730a8a7bc3" rel="noopener noreferrer" target="_blank" style={{color: "inherit"}}>
                        <u>CMU admissions process</u></a></p>
                </Grid.Column>

                <Grid.Column width ={4} textAlign="center">
                    <Image src={ADPList_2024} rounded />
                    <p style={{marginTop: "3%"}}>Mentorship milestone on &nbsp;
                    <a href="https://adplist.org/mentors/harshika-jain" rel="noopener noreferrer" target="_blank" style={{color: "inherit"}}>
                        <u>ADPList</u></a></p>
                </Grid.Column>

                <Grid.Column width={4} />
            </Grid.Row>     
            
            <Divider hidden />
            {/* 2023 list of contributions  */}                
            <Grid.Row><Grid.Column><Header as="h2">2023</Header></Grid.Column></Grid.Row>                
            <Grid.Row>
                <Grid.Column width ={4} textAlign="center">
                    <ImageCarousel
                        showDots={false}
                        sliderContent={[
                        <Image key={1} src={Cornell_1_2023} rounded bordered />,
                        <Image key={2} src={Cornell_2_2023} rounded bordered />,]}
                    />
                    <p style={{marginTop: "3%"}}>HCI Lecture @ Cornell Tech</p>
                </Grid.Column>
            
                <Grid.Column width ={4} textAlign="center">
                    <Image src={Interactive_Design_2023} rounded />
                    <p style={{marginTop: "3%"}}>Co-authored a {" "}
                    <a href="https://www.amazon.com/Interactive-Design-Towards-Responsive-Environment/dp/303562688X" rel="noopener noreferrer" target="_blank" style={{color: "inherit"}}>
                        <u>book chapter</u></a></p>
                </Grid.Column>

                <Grid.Column width ={4} textAlign="center">
                    <ImageCarousel
                        showDots={false}
                        sliderContent={[
                        <Image key={1} src={HackHarvard_2_2023} rounded bordered />,
                        <Image key={2} src={HackHarvard_1_2023} rounded bordered />,]}
                    />
                    <p style={{marginTop: "3%"}}>Lecture + Panel discussion @ Harvard</p>
                </Grid.Column>

                <Grid.Column width ={4} textAlign="center">
                    <Image src={HackMIT_2023} rounded />
                    <p style={{marginTop: "3%"}}>Judge @ HackMIT</p>
                </Grid.Column>
            </Grid.Row>
            
            <Divider hidden />
            {/* 2022 list of contributions  */}
            <Grid.Row><Grid.Column><Header as="h2">2022</Header></Grid.Column></Grid.Row>                
            <Grid.Row>
                <Grid.Column width ={4} textAlign="center">
                    <Image src={cc_1_2022} rounded />
                    <p style={{marginTop: "3%"}}><a href="https://x.com/acm_cc/status/1538879442784772096" rel="noopener noreferrer" target="_blank" style={{color: "inherit"}}>
                    <u>Workshop</u></a> on tangible interactions, Venice </p>
                </Grid.Column>

                <Grid.Column width ={4} textAlign="center">
                    <ImageCarousel showDots={false} sliderContent={[
                        <Image key={1} src={cc_2_2022} rounded bordered />,
                        <Image key={2} src={cc_3_2022} rounded bordered />,]}
                    />
                    <p style={{marginTop: "3%"}}>Demoed morphing materials, Venice</p>
                </Grid.Column>

                <Grid.Column width ={4} textAlign="center">
                    <Image src={CMUonline_2022} rounded bordered/>
                    <p style={{marginTop: "3%"}}>Recorded a class for CMU's online master's program</p>
                </Grid.Column>

                <Grid.Column width ={4} textAlign="center">
                    <ImageCarousel showDots={false} sliderContent={[
                        <Image key={1} src={witBoston_1_2022} rounded bordered />,
                        <Image key={2} src={witBoston_2_2022} rounded bordered />,]}
                    />
                    <p style={{marginTop: "3%"}}>Workshop on martial arts at WIT Boston</p>
                </Grid.Column>
            </Grid.Row>

            <Divider hidden />
            {/* 2021 and earlier list of contributions  */}
            <Grid.Row><Grid.Column><Header as="h2">2021 - earlier</Header></Grid.Column></Grid.Row>                
            <Grid.Row>
                <Grid.Column width ={4} textAlign="center">
                    <Image src={ARS_Electronica_2020} rounded />
                    <p style={{marginTop: "3%"}}>Featured on <a href="https://ars.electronica.art/keplersgardens/en/morphing-matter-is/" rel="noopener noreferrer" target="_blank" style={{color: "inherit"}}><u>ARS Electronica</u></a></p>
                </Grid.Column>

                <Grid.Column width ={4} textAlign="center">
                    <Image src={PlanT_2021} rounded bordered/>
                    <p style={{marginTop: "3%"}}><a href="https://youtu.be/tQge9EFZ_Rk?si=PMMIRBvtJ0KSRDbH" rel="noopener noreferrer" target="_blank" style={{color: "inherit"}}><u>Podcast</u></a> on PlanT</p>
                </Grid.Column>

                <Grid.Column width ={4} textAlign="center">
                    <ImageCarousel showDots={false} sliderContent={[
                        <Image key={1} src={designBuddies_1} rounded bordered />,
                        <Image key={2} src={designBuddies_2} rounded bordered />,]}
                    />
                    <p style={{marginTop: "3%"}}>UX & Product meetup with Design Buddies</p>
                </Grid.Column>

                <Grid.Column width ={4} textAlign="center">
                    <Image src={NID_2014} rounded bordered/>
                    <p style={{marginTop: "3%"}}>Speech during Bachelor's</p>
                </Grid.Column>
            </Grid.Row>

            <Divider hidden />
            <Grid.Row style={{marginTop: "8%"}}>
                <Grid.Column width={8}>
                    <Header as="h3">Service to the community</Header>
                    <p style={{marginTop: "2%"}}>2024 - MIIPS admissions panel @ Carnegie Mellon University</p>
                    <p style={{marginTop: "-2%"}}>2024 - Medium Article on CMU&nbsp;<a href="https://medium.com/@harshikerfuffle/applying-to-the-miips-program-at-cmu-9a730a8a7bc3" rel="noopener noreferrer" target="_blank" style={{color: "inherit"}}><u>MIIPS admissions process</u></a></p>
                    <p style={{marginTop: "-2%"}}>2024 - Mentor at HackDisability, organized by Perkins School for the Blind @ MIT</p>
                    <p style={{marginTop: "-2%"}}>2024 - Judge at BluePrint @ MIT</p>
                    <p style={{marginTop: "-2%"}}>2024 - Invited Design Thinking &nbsp;
                        <a href="https://www.linkedin.com/posts/productbuildingjamming_designsprint-innovation-productdesign-activity-7180539528221237248-CJcb?" rel="noopener noreferrer" target="_blank" style={{color: "inherit"}}>
                        <u>Workshop</u></a> @ Babson College</p>
                    <p style={{marginTop: "-2%"}}>2024 - Judge at HackBeanPot @ Northeastern University</p>
                    <p style={{marginTop: "-2%"}}>2023 - Selected HCI track reviewer @ Grace Hopper Celebration</p>
                    <p style={{marginTop: "-2%"}}>2023 - Judge @ HackMIT</p>
                    <p style={{marginTop: "-2%"}}>2023 - Judge @ HackHarvard</p>
                    <p style={{marginTop: "-2%"}}>2022 - <a href="https://x.com/acm_cc/status/1538879442784772096" rel="noopener noreferrer" target="_blank" style={{color: "inherit"}}>
                    <u>Workshop</u></a> on tangible interactions @ ACM C&C Conference, Venice </p>
                    <p style={{marginTop: "-2%"}}>2022 - Workshop on martial arts @ Women in Tech Conference, Boston, MA</p>
                    <p style={{marginTop: "-2%"}}>2022 - Judge at TechTogether @ Boston University</p>
                    <p style={{marginTop: "-2%"}}>2022 - Judge @ HackMIT</p>
                    <p style={{marginTop: "-2%"}}>2021 - UX & Product meetup @ Grace Hopper Celebration with Design Buddies</p>
                    <p style={{marginTop: "-2%"}}>2021 - Present - Mentor on <a href="https://adplist.org/mentors/harshika-jain" rel="noopener noreferrer" target="_blank" style={{color: "inherit"}}>
                        <u>ADPList</u></a></p>
                    <p style={{marginTop: "-2%"}}>2020 - Board member @ <a href="https://www.cmute.io/" rel="noopener noreferrer" target="_blank" style={{color: "inherit"}}>
                        <u>CMU Tech and Entrepreneurship</u></a></p>
                    <p style={{marginTop: "-2%"}}>2020 - Design Jury Reviewer @ Anant National University, India</p>
                    <p style={{marginTop: "-2%"}}>2019 - Selected Mentor at Quick Startup Weekend @ CMU, Qatar</p>
                    <br />
                </Grid.Column>
                
                <Grid.Column width={8}>
                    <Header as="h3">Invited talks</Header>
                        <p style={{marginTop: "2%"}}>2024 - Guest speaker @ McGill University SCS, organized by CircuitStream</p>
                        <p style={{marginTop: "-2%"}}>2024 - Podcast on <a href="https://open.spotify.com/episode/1aG34zROlFN0LPEADRYnhF" rel="noopener noreferrer" target="_blank" style={{color: "inherit"}}><u>How AI will affect enterprise SaaS</u></a> @ Product Valley</p>
                        <p style={{marginTop: "-2%"}}>2024 - Podcast '<a href="https://youtu.be/mTQvydSiaf4?si=oiIJK0b9gCHARWAw" rel="noopener noreferrer" target="_blank" style={{color: "inherit"}}><u>From Concept to Creation</u></a>' @Product Unfiltered</p>
                        <p style={{marginTop: "-2%"}}>2023 - Guest lecture for an HCI class @ Cornell Tech, NY</p>
                        <p style={{marginTop: "-2%"}}>2023 - Panelist on 'Career and Tech' panel @ Harvard University, MA</p>
                        <p style={{marginTop: "-2%"}}>2023 - Talk on <a href="https://www.meetup.com/princeton-school-of-ai/events/296652017/" rel="noopener noreferrer" target="_blank" style={{color: "inherit"}}><u>AI Product Development</u></a> @ Princeton School of AI meetup</p>
                        <p style={{marginTop: "-2%"}}>2022 - Recorded a class on 'Product Development' for CMU's online degree program</p>
                        <p style={{marginTop: "-2%"}}>2022 - Guest lecture for an Enterprise Innovation class @ CMU, Silicon Valley</p>
                        <p style={{marginTop: "-2%"}}>2022 - Guest lecture for a Design Management class @ National Institute of Design, India</p>
                        <p style={{marginTop: "-2%"}}>2022 - Guest lecture on 'Building Hyperloop' @ UNC Charlotte, NC</p>
                        <p style={{marginTop: "-2%"}}>2021 - Podcast <a href="https://youtu.be/tQge9EFZ_Rk?si=PMMIRBvtJ0KSRDbH" rel="noopener noreferrer" target="_blank" style={{color: "inherit"}}><u>about my experience at CMU</u></a> @ PlanT</p>
                        <p style={{marginTop: "-2%"}}>2020 - Morphing Matter showcase for <a href="https://ars.electronica.art/keplersgardens/en/morphing-matter-is/" rel="noopener noreferrer" target="_blank" style={{color: "inherit"}}><u>ARS Electronica</u></a>, 6:30</p>
                        
                </Grid.Column>
                
            </Grid.Row>
          </Grid>
        </Container>

        <SiteFooter />
      </>
    );
}
