import React from "react";
import { Header, Grid, Image, Container, Divider } from "semantic-ui-react";
import HorizontalRule from "../../components/HorizontalRule";
import SiteFooter from "../../components/SiteFooter";

/* cSpell:disable */
const HeroImage ="./images/SandBox/CancerCare/Hero_1.png";

const journey_map = "images/SandBox/CancerCare/journey_map.png";
const opportunity_canvas = "images/SandBox/CancerCare/opportunity_canvas.png";
const space_planning_params = "images/SandBox/CancerCare/space_planning_params.png";

export default function CancerCare() {

  return (
    <>
    <Container>
      <Grid stackable columns>
        {/* Hero Image */}
        <Grid.Row centered>
            <Header as="h2">Chemotherapy OPD experience @GE Healthcare</Header>
            <img src={HeroImage} alt="work in progress" />
        </Grid.Row>

        {/* ________________________________________________________________________________ */}
        {/* Intro and Role */}
        <Grid.Row>
          <Grid.Column width={11}>
              <Header as="h3">
              How might we enhance the care ecosystem for people undergoing chemotherapy?<br />
              </Header>
              <p style={{ marginBottom: "3%", fontSize: "16px" }}>
                Medicine is not just about healing. The population will continue
                to get sicker until the medical industry addresses the cause of
                the illness with the same brilliance with which they address the
                effects. <br />
                <br />
                Strengthen GE’s business strategy to offer holistic solutions to clients beyond their flagship medical devices, by 
                operationalizing the vision of a non-hospital environment for Chemotheraphy OPD.</p>
            </Grid.Column>

            <Grid.Column width={5}>
              <Header as="h4">My Role</Header>
              <p style={{ margin: "-1.5% 0px 3%" }}>Product Innovation Designer - Thesis project.</p>

              <Header as="h4">Duration</Header>
              <p style={{ margin: "-1.5% 0px 3%" }}>Apr - Oct 2017</p>
            </Grid.Column>
          </Grid.Row>

          <HorizontalRule size="large" />

        <Grid.Row style={{ marginBottom: "4em" }}>
          <Grid.Column>
            <Header as="h3">User Journey Map</Header>
            <p style={{ marginBottom: "1em" }}>
              The inferences from the user studies and field research helped in
              plotting a user journey map along with behavioral mapping at each
              stage. The map has been split into 3 parts - before appointment,
              during chemotherapy and after the chemo appointment.
            </p>

            <Image
              src={journey_map}
              // onMouseOver={() => {
              //   setOnHover(!hover);
              // }}
              // onMouseOut={() => {
              //   setOnHover(hover);
              // }}
              // style={{ transform: `${hover && "scale(2)"}` }}
            />
          </Grid.Column>
        </Grid.Row>

        <Grid.Row style={{ marginBottom: "4em" }}>
          <Grid.Column>
            <Header as="h3">Opportunities in Space Planning</Header>
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column width={5}>
            <p>
              Improving the ambience of the Out-Patient Unit was identified as a
              major touch-point since people spend an average of 6-8 hours there
              during their Chemotherapy.
              <br />
              <br />
              The opportunities were plotted based on cost considerations and
              impact to cater to different budget ranges of client hospitals
            </p>
          </Grid.Column>
          <Grid.Column width={11}>
            <Image src={opportunity_canvas} />
          </Grid.Column>
        </Grid.Row>

        <Divider hidden />

        <Grid.Row>
          <Grid.Column>
            <Header as="h4" style={{ marginBottom: "2em" }}>
              Parameters for Space Planning in the Daycare
            </Header>
            <Image src={space_planning_params} />
          </Grid.Column>
        </Grid.Row>

        {/* _________________wrote some really cool code___________________
        <Grid.Row>{readMoreAction}</Grid.Row>
        _________________wrote some really cool code___________________*/}
        {/* <Grid.Row>
          <ReadMoreButton>
            <Grid.Row>
              <Grid.Column width={8}>
                <span>
                  Medicine is not just about healing. The population will
                  continue to get sicker until the medical industry addresses
                  the cause of the illness with the same brilliance with which
                  they address the effects. <br />
                  <br />
                  How can we build systems in which CURE is supplemented by
                  CARE?
                </span>
              </Grid.Column>
            </Grid.Row>
          </ReadMoreButton>
        </Grid.Row>

        <Grid.Row>
          <ReadMoreLink lessTitle="Why Customize" moreTitle="Why Customize">
            <Grid.Row>
              <Grid.Column width={8}>
                <span>
                  Medicine is not just about healing. The population will
                  continue to get sicker until the medical industry addresses
                  the cause of the illness with the same brilliance with which
                  they address the effects. <br />
                  <br />
                  How can we build systems in which CURE is supplemented by
                  CARE?
                </span>
              </Grid.Column>
            </Grid.Row>
          </ReadMoreLink>
        </Grid.Row> */}

        {/* ________________________________________________________________________________ */}
      </Grid>
    </Container>
    <SiteFooter />
    </>
  );
}
